<template>
  <div id="wrap">
    <div id="header">
      <div class="gnbwrap">
        <h1 class="m_logo">
          <router-link to="/home" id="mainLogoImg"
            ><img
              src="@/assets/img/m_logo_KO.png"
              alt="대한민국 비자포털(비자포털 방문을 환영합니다.)"
          /></router-link>
        </h1>
        <!-- 탑메뉴 -->
        <ul class="top_m">
          <li>
            <a href="javascript:void(0)" id="top_a_home" class="user_home"
              >홈</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="top_a_login">로그인</a
            ><span></span>
          </li>
          <li>
            <a href="javascript:void(0)" id="top_a_member">회원가입</a
            ><span></span>
          </li>

          <li id="top_a_sitemap">
            <a href="javascript:void(0)"
              >사이트맵<span id="user_map_span" class="user_map"></span
            ></a>
          </li>

          <li>
            <a href="javascript:void(0)" id="top_a_lang_en" class="change_lang"
              >ENGLISH</a
            >
          </li>

          <li>
            <a href="javascript:void(0)" id="top_a_lang_ch" class="change_lang"
              >中文</a
            >
          </li>
        </ul>
        <!-- // 탑메뉴 -->
        <!-- gnb -->
        <ul class="main_menu group">
          <li class="main_m0" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_101">비자안내</a>
          </li>
          <li class="main_m1" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_102">신청</a>
          </li>
          <li class="main_m2" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_103" class="on"
              >조회/발급</a
            >
          </li>
          <li class="main_m3" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_104">투자이민</a>
          </li>
          <li class="main_m4" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_105">고객센터</a>
          </li>
          <li class="dis_none main_m5" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_106">회원</a>
          </li>
          <li class="dis_none main_m6" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_107">마이페이지</a>
          </li>
          <li class="dis_none main_m7" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_108">기타</a>
          </li>
          <li class="dis_none main_m8" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_109">비자신청센터</a>
          </li>
          <li class="dis_none main_m9" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_110">계절근로자</a>
          </li>
          <li class="main_m11" style="padding: 3px 0px 0px 42px">
            <a href="javascript:void(0)" id="gnb_a_menu_112">비자신청센터</a>
          </li>
        </ul>
        <!-- // gnb -->
      </div>
    </div>

    <div id="container" class="process_search group">
      <div id="lnb">
        <div>
          <h2 id="lnb_h2_title">조회/발급</h2>
          <ul id="lnb_ul_sub_menu" class="sub_menu">
            <li>
              <a href="javascript:void(0)" id="lnb_a_menu_10301" class="on"
                >진행현황 조회 및 출력
              </a>
              <span id="lnb_span_permission_10301" class="dis_none">Y</span>
              <span id="lnb_span_ko_use_yn_10301" class="dis_none">Y</span>
              <span id="lnb_span_en_use_yn_10301" class="dis_none">Y</span>
              <span id="lnb_span_ch_use_yn_10301" class="dis_none">Y</span>
            </li>
            <li>
              <a href="javascript:void(0)" id="lnb_a_menu_10302"
                >방문취업당첨확인
              </a>
              <span id="lnb_span_permission_10302" class="dis_none">Y</span>
              <span id="lnb_span_ko_use_yn_10302" class="dis_none">Y</span>
              <span id="lnb_span_en_use_yn_10302" class="dis_none">Y</span>
              <span id="lnb_span_ch_use_yn_10302" class="dis_none">Y</span>
            </li>
          </ul>
          <a href="javascript:void(0)" id="lnb_a_link_visa" class="link_visa"
            ><img
              src="@/assets/img/link_visa_KO.png"
              alt="Visa Navigator 나에게 맞는 비자 찾아보기 go"
          /></a>
          <a
            href="javascript:void(0)"
            id="hikorea_link_visa"
            class="link_visa"
            style="margin-top: 10px; display: none"
            ><img src="@/assets/img/hikorea_KO.gif" alt="HI KOREA 이동"
          /></a>
        </div>
      </div>
      <!-- 콘텐츠 시작 -->
      <div id="contents">
        <p class="page_navi">조회/발급<span>&gt;</span>진행현황 조회 및 출력</p>
        <h3>진행현황 조회 및 출력</h3>
        <!-- 설명 -->
        <div class="search_style01">
          <div>
            <ul>
              <li class="bg_dot line_h15">
                재외공관, 전자비자, 단체비자, 비자발급인정서 중 신청하신 항목을
                선택하시고,
                <!-- 전자비자, 비자발급인정서, 온라인 신청 중 신청하신 항목을 선택하시고, -->
                <br /><span class="red_txt_l"
                  >신청번호, 여권번호, 또는 접수번호와 영문성명, 생년월일을 모두
                  입력하셔야 조회가 가능합니다.
                  <!-- 신청번호, 여권번호, 또는 조회번호와 영문성명, 생년월일을 모두 입력하셔야 조회가 가능합니다. --></span
                >
              </li>
            </ul>
          </div>
        </div>
        <!--  //설명 -->
        <h4><span class="h4_ico"></span>조회</h4>
        <!-- 상단 검색 영역 -->
        <div class="search_zone">
          <!-- 검색 내용 -->
          <div class="search_style01 group">
            <div class="check_application mgb_20">
              <span class="hide">필수입력</span
              ><span class="t_point02">신청종류<!-- 신청종류 --></span>
              <input
                type="radio"
                id="RADIOSEARCH03"
                class="mgl_20"
                name="RADIOSEARCH"
                value="gb03"
                checked="checked"
              />
              <label for="RADIOSEARCH03">재외공관 </label>
              <input
                type="radio"
                id="RADIOSEARCH01"
                class="mgl_13"
                name="RADIOSEARCH"
                value="gb01"
              />
              <label for="RADIOSEARCH01">전자비자 (개인)</label>
              <input
                type="radio"
                id="RADIOSEARCH04"
                class="mgl_13"
                name="RADIOSEARCH"
                value="gb04"
              />
              <label for="RADIOSEARCH04">전자비자 (단체)</label>
              <input
                type="radio"
                id="RADIOSEARCH02"
                class="mgl_13"
                name="RADIOSEARCH"
                value="gb02"
              />
              <label for="RADIOSEARCH02">비자발급인정서</label>
              <input
                type="radio"
                id="RADIOSEARCH05"
                class="mgl_13"
                name="RADIOSEARCH"
                value="gb05"
              />
              <label for="RADIOSEARCH05">비자신청센터</label>
              <input
                type="radio"
                id="RADIOSEARCH06"
                class="mgl_13"
                name="RADIOSEARCH"
                value="gb06"
              />
              <label for="RADIOSEARCH06">무비자</label>
            </div>

            <!-- 검색 조건 type1 -->
            <div class="fl_left">
              <div id="searchType1" style="display: none" class="mgb_12">
                <span class="hide">필수입력</span
                ><label for="sINVITEE_SEQ" class="t_point02 process_mar04"
                  >신청번호</label
                >
                <input
                  type="text"
                  id="sINVITEE_SEQ"
                  class="process_mar05"
                  style="ime-mode: disabled; text-transform: uppercase"
                />
                <span class="hide">필수입력</span
                ><label for="sPASS_NO" class="t_point02 process_mar06"
                  >여권번호</label
                >
                <input
                  type="text"
                  id="sPASS_NO"
                  class="process_mar07"
                  style="ime-mode: disabled; text-transform: uppercase"
                />
              </div>

              <div id="searchType2" class="mgb_12">
                <span class="hide">필수입력</span
                ><label for="BUSI_GB" class="t_point02 process_mar01"
                  >구분</label
                >
                <select id="BUSI_GB" name="BUSI_GB" class="sel_1 process_mar02">
                  <option value="PASS_NO">여권번호</option>
                  <option value="ONLINE_APPL_NO">(전자서식)신청번호</option>
                  <option value="RECPT_NO">접수번호</option>
                </select>

                <input
                  type="text"
                  v-model="search.visa_number"
                  id="sBUSI_GBNO"
                  class=""
                  value=""
                  title="구분에 따른 번호 입력란"
                  style="
                    width: 190px;
                    margin-left: 15px;
                    ime-mode: disabled;
                    text-transform: uppercase;
                  "
                />
              </div>

              <div id="searchType_gb02" style="display: none" class="mgb_12">
                <span class="hide">필수입력</span
                ><label for="BUSI_GB_gb02" class="t_point02 process_mar01"
                  >구분</label
                >
                <select
                  id="BUSI_GB_gb02"
                  name="BUSI_GB_gb02"
                  class="sel_1 process_mar14"
                >
                  <option value="INVITEE_SEQ_gb02">신청번호</option>
                  <option value="RECPT_NO_gb02">접수번호</option>
                </select>
                <input
                  type="text"
                  id="sBUSI_GBNO_gb02"
                  title="구분에 따른 번호 입력"
                  class="process_mar12"
                  value=""
                  style="ime-mode: disabled; text-transform: uppercase"
                />
                <span class="hide">필수입력</span
                ><label for="sPASS_NO_gb02" class="t_point02 process_mar06"
                  >여권번호</label
                >
                <input
                  type="text"
                  id="sPASS_NO_gb02"
                  class="process_mar13"
                  style="ime-mode: disabled; text-transform: uppercase"
                  onblur="javascript:gfn_spaceName(this);"
                />
              </div>

              <div class="searchType_gb06 mgb_12" style="display: none">
                <span class="hide">필수입력</span
                ><label for="JEJU_VF_SEQ" class="t_point02 process_mar04"
                  >대표신청번호</label
                >
                <input
                  type="text"
                  id="JEJU_VF_SEQ"
                  class="process_mar05"
                  style="ime-mode: disabled; text-transform: uppercase"
                  onblur="javascript:gfn_isNumberOnly('JEJU_VF_SEQ');"
                />
                <span class="hide">필수입력</span
                ><label for="JEJU_PASS_NO" class="t_point02 process_mar06"
                  >여권번호</label
                >
                <input
                  type="text"
                  id="JEJU_PASS_NO"
                  class="process_mar07"
                  style="ime-mode: disabled; text-transform: uppercase"
                />
              </div>
              <div id="searchType_common">
                <span class="hide">필수입력</span
                ><label for="sEK_NM" class="t_point02 process_mar08"
                  >영문성명</label
                >
                <input
                  type="text"
                  v-model="search.name"
                  id="sEK_NM"
                  class="process_mar09"
                  value=""
                  style="ime-mode: disabled; text-transform: uppercase"
                  onkeyup="characterCheck(this)"
                  onkeydown="characterCheck(this)"
                />
                <span class="hide">필수입력</span
                ><label
                  for="sFROMDATE"
                  class="t_point02 process_mar10"
                  title="예시)19990101"
                  >생년월일</label
                ><!-- <input type="text" id="BIRTH_YMD" class="w200 mgl_15" value="" /> -->
                <input
                  id="sFROMDATE"
                  v-model="date_of_birth"
                  name="sFROMDATE"
                  type="text"
                  class="btn_calendar process_mar11 align_l maskctl-edit hasDatepicker"
                  title="예시)19990101"
                  autocomplete="off"
				  @keydown.delete="handleDelete"

                /><img
                  style="margin-bottom: -6px; margin-left: 8px"
                  class="ui-datepicker-trigger"
                  src="@/assets/img/ico_calendar.gif"
                  alt="날짜선택"
                  title="날짜선택"
                />
                <p class="red_txt_l mgt_5">
                  여권상의 영문 성명을 입력 해 주십시오.<!-- (여권상의 영문 성명을 입력 해 주십시오) -->
                </p>
              </div>

              <div class="searchType_gb05 mgb_12" style="display: none">
                <span class="hide">필수입력</span
                ><label for="VR_RECPT_NO" class="t_point02 process_mar04"
                  >신청번호</label
                >
                <input
                  type="text"
                  id="VR_RECPT_NO"
                  class="process_mar05"
                  style="ime-mode: disabled; text-transform: uppercase"
                />
                <span class="hide">필수입력</span
                ><label for="VR_PASS_NO" class="t_point02 process_mar06"
                  >여권번호</label
                >
                <input
                  type="text"
                  id="VR_PASS_NO"
                  class="process_mar13"
                  style="ime-mode: disabled; text-transform: uppercase"
                />
              </div>
              <div class="searchType_gb05" style="display: none">
                <span class="hide">필수입력</span
                ><label for="VR_ENG_NM" class="t_point02 process_mar04"
                  >영문성명</label
                >
                <input
                  type="text"
                  id="VR_ENG_NM"
                  class="process_mar05"
                  value=""
                  style="ime-mode: disabled; text-transform: uppercase"
                  onkeyup="characterCheck(this)"
                  onkeydown="characterCheck(this)"
                />
                <span class="hide">필수입력</span
                ><label for="VR_BIRTH_YMD" class="t_point02 process_mar06"
                  >생년월일</label
                >
                <input
                  id="VR_BIRTH_YMD"
                  name="VR_BIRTH_YMD"
                  type="text"
                  class="btn_calendar process_mar11 align_l maskctl-edit hasDatepicker"
                  title="예시)19990101"
                  autocomplete="off"
                /><img
                  class="ui-datepicker-trigger"
                  src="@/assets/img/ico_calendar.gif"
                  alt="날짜선택"
                  title="날짜선택"
                />
                <p class="red_txt_l mgt_5">
                  여권상의 영문 성명을 입력 해 주십시오.<!-- (여권상의 영문 성명을 입력 해 주십시오) -->
                </p>
              </div>
            </div>
            <!-- //검색 조건 type1 -->

            <div
              class="fl_left mgl_25"
              @click="searchInfo()"
              @touchstart="searchInfo()"
            >
              <a href="javascript:void(0)" id="searchBtn" name="searchBtn"
                ><img src="@/assets/img/search_sbtn.gif" alt="Search"
              /></a>
            </div>
          </div>
          <!-- //검색 내용 -->
        </div>
        <!-- //상단 검색 영역 -->

        <div id="result0_1" v-if="data.length > 0">
          <h4><span class="h4_ico"></span>검색결과</h4>
          <div class="h4_button_st">
            <h5 class="h5_style01">진행현황정보</h5>
            <div class="h5_btn_right">
              <a
                href="javascript:void(0)"
                id="DETAIL_INFO"
                name="DETAIL_INFO"
                class="btn_search02"
                ><span class="btn_search_start"></span>상세정보보기<span
                  class="btn_search_end"
                ></span
              ></a>
            </div>
          </div>
        </div>

        <div id="result1_1" v-if="data.length > 0">
          <table class="board_view02 mgb_35">
            <caption>
              진행현황정보<span
                >진행현황정보로 신청번호, 신청일자, 체류자격, 진행상태,
                초청자/초청회사, 피초청자, 비자종류, 심사일자로 구성되어
                있습니다.</span
              >
            </caption>
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 30%" />
              <col style="width: 20%" />
              <col style="width: auto" />
            </colgroup>
            <!-- {{data}} -->
            <tbody>
              <!-- <tr id="ev_evNo" >
								<th scope="row"><label for="EV_NO"> 전자비자번호</label></th>
								<td><div id="EV_NO"></div></td> 
								<th scope="row"><label for="EV_EXPR_YMD">유효기간</label></th>
								<td><div id="EV_EXPR_YMD"></div></td>														
							</tr>
							<tr id="ev_ccviNo" >
								<th scope="row"><label for="CCVI_NO">비자발급인정서 번호</label></th>
								<td colspan="3"><div id="CCVI_NO"></div></td>																					
							</tr> -->
              <tr>
                <th v-if="data[0].visa_number" scope="row">
                  <label for="INVITEE_SEQ">신청번호</label>
                </th>
                <td v-if="data[0].visa_number" @click="showVisa()">
                  <div id="INVITEE_SEQ">{{ data[0].visa_number }}</div>
                </td>
                <th v-if="data[0].register_date" scope="row">
                  <label for="APPL_YMD">신청일자</label>
                </th>
                <td v-if="data[0].register_date" @click="showVisa()">
                  <div id="APPL_YMD">
                    {{ formatDate(data[0].register_date) }}
                  </div>
                </td>
              </tr>
              <tr>
                <th v-if="data[0]?.status_of_residence" scope="row">
                  <label for="SOJ_QUAL_NM">체류자격</label>
                </th>
                <td v-if="data[0]?.status_of_residence" @click="showVisa()">
                  <div id="SOJ_QUAL_NM">{{ data[0]?.status_of_residence }}</div>
                </td>
                <th v-if="data[0].process_status" scope="row">
                  <label for="PROC_STS_CDNM">진행상태</label>
                </th>

                <!-- 전자비자,개인,단체,비자발급인정서(신청번호) -->
                <td v-if="data[0].process_status" @click="showVisa()">
                  <div id="PROC_STS_CDNM">
                    {{ data[0].process_status }}
                  </div>
                </td>
              </tr>

              <tr>
                <th v-if="data[0]?.business_in_korea" scope="row">
                  <label for="MEM_NM">초청자/초청회사</label>
                </th>
                <td v-if="data[0]?.business_in_korea" @click="showVisa()">
                  <div id="MEM_NM">{{ data[0]?.business_in_korea }}</div>
                </td>
                <th v-if="data[0]?.invited_person" scope="row">
                  <label for="EK_NM">피초청자</label>
                </th>
                <td v-if="data[0]?.invited_person" @click="showVisa()">
                  <div id="EK_NM">{{ data[0]?.invited_person }}</div>
                </td>
              </tr>

              <tr id="VISA_GB_JUDG_DTM" style="display: none">
                <th scope="row">
                  <label for="APPL_VISA_GBNM">비자종류</label>
                </th>
                <td>
                  <div id="APPL_VISA_GBNM"></div>
                </td>
                <th scope="row"><label for="JUDG_DTM">심사일자</label></th>
                <td>
                  <div id="JUDG_DTM"></div>
                </td>
              </tr>
              <tr id="NONPERMRSNCD" style="display: none">
                <th scope="row">사유<!-- 사유 --></th>
                <td colspan="3">
                  귀하의 비자신청에 대한 불허사유는 다음과 같습니다 :
                  <br />
                </td>
              </tr>
              <tr>
                <th v-if="data[0]?.reason_for_entry" scope="row">
                  <label for="ENTRY_PURPOSE">입국목적</label>
                </th>
                <td v-if="data[0]?.reason_for_entry" @click="showVisa">
                  <div id="ENTRY_PURPOSE">{{ data[0]?.reason_for_entry }}</div>
                </td>
                <!-- //TIEN TRIEN -->
                <th v-if="data[0]?.approved_date" scope="row">
                  <label for="PROC_STS_CDNM_1">승인일</label>
                </th>
                <td v-if="data[0]?.approved_date" @click="showVisa">
                  <div id="PROC_STS_CDNM_1">
                    {{ data[0]?.approved_date }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="result2_1" style="display: none">
          <h5 class="h5_style01">수수료결제정보</h5>

          <table class="board_view02 mgb_40">
            <caption>
              수수료결제정보<span>수수료결제정보</span>
              : 결제 항목, 결제상태, 결제금액, 결제일 정보제공
            </caption>
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 30%" />
              <col style="width: 20%" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row"><label for="TITLE">결제 항목</label></th>
                <td>
                  <div id="TITLE"></div>
                </td>
                <th scope="row"><label for="TR_GB">결제상태</label></th>
                <td>
                  <div id="TR_GB"></div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="TOT_PRICE">결제금액</label></th>
                <td>
                  <div id="TOT_PRICE"></div>
                </td>
                <th scope="row"><label for="PAY_YMD">결제일</label></th>
                <td>
                  <div id="PAY_YMD"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom_btn01 group">
          <a
            href="javascript:void(0)"
            id="BTN_FEE_RECEIPT_PRINT"
            name="BTN_FEE_RECEIPT_PRINT"
            class="btn_blue_b"
            style="display: none"
            >전자수수료 영수증 출력<!-- 전자수수료 영수증 출력 --></a
          >
          <a
            href="javascript:void(0)"
            id="BTN_CCVI_PRINT"
            name="BTN_CCVI_PRINT"
            class="btn_blue_b"
            style="display: none"
            >비자발급신청서 출력<!-- 비자발급인정서출력 --></a
          >
          <a
            href="javascript:void(0)"
            id="PRINT"
            name="PRINT"
            class="btn_green_b"
            style="display: none"
            >접수증 출력<!-- 접수증 출력 (대상: 전자사증 사발인)  --></a
          >
        </div>

        <div id="result3_2" v-if="false">
          <table class="board_view02 mgb_35">
            <caption>
              진행현황정보<span
                >진행현황정보로 접수번호, 신청일자, 체류자격, 진행상태로
                구성되어 있습니다.</span
              >
            </caption>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr v-if="data[0]?.reason_for_entry">
                <th scope="row"><label for="ENTRY_PURPOSE">입국목적</label></th>
                <td>
                  <div id="ENTRY_PURPOSE">{{ data[0]?.reason_for_entry }}</div>
                </td>
                <!-- //TIEN TRIEN -->
                <th scope="row"><label for="PROC_STS_CDNM_1">승인일</label></th>
                <td>
                  <div id="PROC_STS_CDNM_1">
                    {{ data[0]?.approved_date }}
                  </div>
                </td>
              </tr>
              <!-- <tr id="INTNET_OPEN_REJ_RSN_CD" style="display:none;">
								<th scope="row" class="no_reason">귀하의 비자신청에 대한 불허사유는 다음과 같습니다 : </th>
								<td colspan="3">












								</td>
							</tr> -->
            </tbody>
          </table>
        </div>

        <div id="result3_3" style="display: none"></div>

        <div id="result4_1" style="display: none">
          <p class="h4_summary invest_favor">
            <span
              >비자신청센터에서 사증을 신청하신 분은 진행현황이 "허가/불허"인
              경우에는 여권을 찾을 수 없으며, "여권교부"인 경우에만 찾을 수
              있습니다.
              <!-- 비자신청센터에서 사증을 신청하신 분은 진행현황이 '허가/불허'인 경우에는 여권을 찾을 수 없으며, '여권교부'인 경우에만 찾을 수 있습니다. --></span
            >
          </p>
          <table class="board_view02 mgb_35">
            <caption>
              진행현황정보<span
                >진행현황정보로 접수번호, 신청일자, 체류자격, 진행상태로
                구성되어 있습니다.</span
              >
            </caption>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row"><label>신청센터명</label></th>
                <td></td>
                <th scope="row"><label>신청일자</label></th>
                <td></td>
              </tr>
              <tr>
                <th scope="row"><label>신청번호</label></th>
                <td></td>
                <th scope="row"><label>진행상태</label></th>
                <td><!--  --></td>
              </tr>
            </tbody>
          </table>
          <!-- 비자센터 비자발급확인서 -->
        </div>

        <div id="result5_1" style="display: none">
          <table class="board_view02 mgb_35">
            <caption>
              진행현황정보<span
                >진행현황정보로 신청번호, 신청상태, 성명, 여권번호, 신청일자로
                구성되어 있습니다.</span
              >
            </caption>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <label for="JEJU_VF_NO">대표신청번호</label>
                </th>
                <td>
                  <div id="JEJU_VF_NO"></div>
                </td>
                <th scope="row">
                  <label for="PROC_STS_CD_NM">진행상태</label>
                </th>
                <td>
                  <div id="PROC_STS_CD_NM"></div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="JEJU_EK_NM">영문성명</label></th>
                <td>
                  <div id="JEJU_EK_NM"></div>
                </td>
                <th scope="row">
                  <label for="JEJU_BIRTH_YMD">생년월일</label>
                </th>
                <td>
                  <div id="JEJU_BIRTH_YMD"></div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="JEJU_PASS_NO1">여권번호</label></th>
                <td colspan="3">
                  <div id="JEJU_PASS_NO1"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="passportImg" style="display: block">
          <div>
            <h4><span class="h4_ico"></span>영문성명 입력 시 주의사항</h4>
            영문성명은 신청 시 제출한 여권의 인적면 하단에 있는
            기계식별판독부분을 기준으로 입력하시기 바랍니다.
          </div>
          <br />

          <div id="testImage">
            <img
              src="@/assets/img/passKo.png"
              alt="영문성명 입력 시 주의사항 이미지"
            />
          </div>
        </div>

        <div id="resultValue" style="display: none">
          <h6 class="h5_style01 tac">조회된 데이터가 없습니다.</h6>
        </div>
        <!-- // 진행현황 정보 테이블 -->
      </div>
      <!-- 콘텐츠 끝 -->
    </div>

    <form
      name="regForm"
      method="post"
      action="https://secureapi.eximbay.com/Gateway/BasicProcessor/2.x/invoice/down.jsp"
    >
      <input type="hidden" name="transid" id="transid" value="" />
      <input type="hidden" name="ref" id="ref" value="" />
      <input type="hidden" name="EV_SEQ" id="EV_SEQ" value="" />
    </form>

    <div id="footer">
      <div class="foot_con01">
        <ul id="ko_foot_list" class="foot_list">
          <li><a href="javascript:void(0)" id="ko_foot_a_use">이용약관</a></li>
          <li class="foot_privacy">
            <a href="javascript:void(0)" id="ko_foot_a_person"
              >개인정보 처리방침</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="ko_foot_a_protect"
              >저작권 보호정책</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="ko_foot_a_email" title="새창 열림"
              >이메일 무단수집거부</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="ko_foot_a_custom">고객센터</a>
          </li>
        </ul>
        <ul id="en_foot_list" class="foot_list dis_none">
          <li>
            <a href="javascript:void(0)" id="en_foot_a_use"
              >Terms and Conditions</a
            >
          </li>
          <li class="foot_privacy">
            <a href="javascript:void(0)" id="en_foot_a_person"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="en_foot_a_protect"
              >Copyright Protection Policy</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="en_foot_a_email" target="_blank"
              >Prohibition of Unauthorized Collection of E-mail Address</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="en_foot_a_custom">Help Center</a>
          </li>
        </ul>
        <ul id="zh_foot_list" class="foot_list dis_none">
          <li><a href="javascript:void(0)" id="zh_foot_a_use">使用条款</a></li>
          <li class="foot_privacy">
            <a href="javascript:void(0)" id="zh_foot_a_person"
              >个人信息处理方针</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="zh_foot_a_protect">版权保护政策</a>
          </li>
          <li>
            <a href="javascript:void(0)" id="zh_foot_a_email" target="_blank"
              >拒绝自动接收邮件</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" id="zh_foot_a_custom">客服中心</a>
          </li>
        </ul>
        <address>
          <p class="address_txt">
            주소 : 경기도 과천시 관문로 47 정부과천청사 1동 ( 외국인종합안내센터
            : 국번없이 1345 )
          </p>
          <p>
            COPYRIGHT©MINISTRY OF JUSTICE. REPUBLIC OF KOREA. ALL RIGHT
            RESERVED.
          </p>
        </address>
        <p class="foot_notice">
          ※ 비자포털은 IE 7 이상, 크롬, 파이어폭스, 사파리, 오페라 브라우저와
          1024*768 화면에 최적화 되어 있습니다.
        </p>
      </div>
      <div class="foot_con02">
        <select
          id="select_family_site"
          name="select_family_site"
          title="관련사이트 선택"
        >
          <option value="">관련 사이트</option>
          <option value="http://www.moj.go.kr">법무부</option>
          <option value="http://www.motie.go.kr">산업통상자원부</option>
          <option value="http://global.seoul.go.kr/">서울글로벌센터</option>
          <option value="http://www.moel.go.kr">고용노동부</option>
          <option
            value="http://www.investkorea.org/ikwork/iko/kor/main/index.jsp"
          >
            인베스트 코리아
          </option>
          <option value="http://www.immigration.go.kr">
            출입국외국인정책본부
          </option>
          <option value="http://www.kotra.or.kr">KOTRA</option>
          <option value="http://www.hrdkorea.or.kr/">한국산업인력공단</option>
          <option value="http://www.keis.or.kr">한국고용정보원</option>
          <option value="http://www.korea.net">KOREA.NET</option>
          <option value="http://world.kbs.co.kr/korean/">KBS WORLD</option>
          <option value="http://www.hikorea.go.kr">하이 코리아</option>
        </select>

        <a
          href="javascript:void(0)"
          class="btn_family"
          id="move_family_site"
          title="새창 이동"
        >
          <span class="btn_start"></span>이동<span class="btn_end"></span>
        </a>
      </div>
    </div>
    <div
      class="overlay"
      v-if="data[0]?.image && openVisa"
      @click="openVisa = false"
    >
      <img style="height: 80vh" :src="data[0]?.image" alt="Your Image" />
    </div>
  </div>
</template>

<script>
import network from "@/core/network";
export default {
  data() {
    return {
      search: {
        name: "",
        visa_number: "",
        date_of_birth: "",
      },
      date_of_birth: "",
      openVisa: false,
      data: {},
    };
  },
  computed: {
    
  },
  watch: {
        date_of_birth(newValue) {
            // Gọi hàm formatDateInput khi date_of_birth thay đổi
            this.formatDateInput(newValue);
        }
    },
  methods: {
	handleDelete(event) {
		this.date_of_birth = ''
    },
	formatDateInput(v) {
		// console.log(e.keyCode , e.which);
	  	let value= v.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
		if (value.length == 4) {
			const year = value.slice(0, 4);
			this.date_of_birth = `${year}. `;
		} else if (value.length == 6) {
			const year = value.slice(0, 4);
			const month = value.slice(4, 6);
			this.date_of_birth = `${year}. ${month}. `;
		} else if (value.length == 8) {
			const year = value.slice(0, 4);
			const month = value.slice(4, 6);
			const day = value.slice(6, 8);
			this.date_of_birth = `${year}. ${month}. ${day}`;
		}
    },
    formatDate(d) {
      // Split the original date into year, month, and day
      const [year, month, day] = d.split("-");

      // Create a new Date object
      const date = new Date(year, month - 1, day); // month - 1 because months are zero-indexed in JavaScript

      // Format the date in the desired format
      const formatted = `${date.getFullYear()}. ${this.padNumber(
        date.getMonth() + 1
      )}. ${this.padNumber(date.getDate())}`;

      // Update the formattedDate data property
      return formatted;
    },
    padNumber(num) {
      // Function to pad numbers with leading zeros if needed
      return num.toString().padStart(2, "0");
    },
    searchInfo() {
      this.openVisa = false;
      this.search.visa_number = this.search.visa_number.trim();
      this.search.name = this.search.name.trim();
      this.search.date_of_birth = this.chuyenDoiNgay(this.date_of_birth);
      network.postNoneAuth("/userInfo/search", this.search).then((res) => {
        this.data = res.data;
      });
    },
    showVisa() {
      this.openVisa = true;
    },
    characterCheck() {
      console.log(2);
    },
    chuyenDoiNgay(ngay) {
		console.log(ngay);
      // Kiểm tra xem ngày được truyền vào có phải là dạng "dd/mm/yyyy" hay "dd.mm.yyyy"
      if (ngay.includes(". ")) {
        // Nếu là dạng "dd/mm/yyyy", thay thế '/' thành '-'
        return ngay.split(". ").join("-");
      }  else {
        // Trường hợp khác, trả về ngày không thay đổi
        return ngay;
      }
    },
  },
};
</script>

<style>
td {
  background: white;
}

td:hover {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Điều chỉnh độ mờ của nền đen tại đây */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay img {
  max-width: 90%;
  max-height: 90%;
}
</style>